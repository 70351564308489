export default {
  env: {
    nodeEnv: process.env.NODE_ENV,
    prodTip: process.env.PROD_TIP,
    devTools: process.env.DEV_TOOLS,
  },
  app: {
    name: process.env.VUE_APP_NAME,
    alias:  process.env.VUE_APP_ALIAS,
    version: process.env.VUE_APP_VERSION,
  },
  layout: {
    preLogin: {
      backgroundImageUri: process.env.VUE_APP_LAYOUT_PRE_LOGIN_BACKGROUND_IMAGE_URI || '/img/bg-01.jpg',
    },
  },
  api: {
    baseUri: process.env.VUE_APP_API_BASE_URI,
    auth: {
      authenticateUri: process.env.VUE_APP_API_AUTH_AUTHENTICATE_URI || '/v1/acesso/Autenticar',
      passwordRecoveryUri: process.env.VUE_APP_API_AUTH_PASSWORD_RECOVERY_URI || '/v1/acesso/SolicitarRecuperacaoSenha',
      newPasswordUri: process.env.VUE_APP_API_AUTH_NEW_PASSWORD_URI || '/v1/acesso/MudarSenha',
      refreshTokenUri: process.env.VUE_APP_API_AUTH_REFRESH_TOKEN_URI || '/v1/acesso/RenovarToken',
      getMenuUri: process.env.VUE_APP_API_AUTH_GET_MENU_URI || '/v1/acesso/ObterMenu',
      getUserPermissionsUri:  process.env.VUE_APP_API_AUTH_GET_USER_PERMISSIONS_URI || '/v1/acesso/ObterPermissoesDoPerfilDoUsuario'
    },
    user: {
      getUserListUri: process.env.VUE_APP_API_USER_LIST || '/v1/Acesso/Usuarios',
      getRegisterUserUri: process.env.VUE_APP_API_USER_REGISTER || '/v1/Acesso/CadastrarUsuario',
      getUpdateUserUri: process.env.VUE_APP_API_USER_UPDATE_REGISTER || '/v1/Acesso/AtualizarCadastroUsuario',
      getDisableUserUri: process.env.VUE_APP_API_USER_DISABLE || '/v1/Acesso/InativarCadastroUsuario',
    },
    groups: {
      getGroupListUri: process.env.VUE_APP_API_GROUPS_LIST || '/v1/Acesso/Perfis',
      getGroupRegisterUri: process.env.VUE_APP_API_GROUP_REGISTER || '/v1/Acesso/CadastrarGrupo',
      getGroupUpdateUri: process.env.VUE_APP_API_GROUP_UPDATE || '/v1/Acesso/AtualizarCadastroGrupo',
      getDisableGroupUri: process.env.VUE_APP_API_GROUP_DISABLE || '/v1/Acesso/InativarCadastroGrupo'
    },
    permissions: {
      getPermissionsListUri: process.env.VUE_APP_API_PERMISSIONS_LIST || '/v1/Acesso/Permissoes' 
    },
    home: {
      faq: process.env.VUE_APP_API_HOME_FAQ_URI,
      obterFiltroDadosRepasse: process.env.VUE_APP_API_HOME_OBTER_FILTRO_DADOS_REPASSE_URI,
      gerarRelatorioDadosRepasse: process.env.VUE_APP_API_HOME_GERAR_RELATORIO_DADOS_REPASSE_URI,
      getListaDocumentosPublicos: process.env.VUE_APP_API_HOME_DOCUMENTOS_PUBLICOS || '/v1/Inicio/Documentos',
      download: process.env.VUE_APP_API_HOME_DOWNLOAD_ARQUIVO || '/v1/Inicio/Download'
    },
    admin: {
      vinculacao:{
        getListaDeGerenteRegional: process.env.VUE_APP_API_ADMIN_LIST_VINCULO || '/v1/Admin/ListarGerentes',
        getListaDeDiretor: process.env.VUE_APP_API_ADMIN_LIST_DIRETOR || '/v1/Admin/ListarDiretor',
        getListaDeGres: process.env.VUE_APP_API_ADMIN_LIST_GRES || '/v1/Admin/ListarGres',
        getListaDeEscolas: process.env.VUE_APP_API_PAF_LISTAR_ESCOLAS || '/v1/Admin/ListarEscolas',
        getAssociarUserGre: process.env.VUE_APP_API_ADMIN_ASSOCIAR_USER_GRES || '/v1/Admin/AssociarUsuarioGre',
        getAssociarUserEscola: process.env.VUE_APP_API_ADMIN_ASSOCIAR_USER_ESCOLA || '/v1/Admin/AssociarUsuarioEscola',
        getDesvincularUserEscola: process.env.VUE_APP_API_ADMIN_DESVINCULAR_USER_ESCOLA || '/v1/Admin/DesassociarUsuarioEscola',
        getUpdateUserGre: process.env.VUE_APP_API_ADMIN_UPDATE_USER_GRES || '/v1/Admin/DesassociarUsuarioGre',
      },
      faq:{
        getFaqs: process.env.VUE_APP_API_ADMIN_FAQ || '/v1/Admin/Faqs',
        getRegisterFaqUri: process.env.VUE_APP_API_ADMIN_FAQ_REGISTER || '/v1/Admin/CadastrarFaq',
        getUpdateFaqUri: process.env.VUE_APP_API_ADMIN_FAQ_UPDATE || '/v1/Admin/AtualizarFaq',
        getDeleteFaqUri: process.env.VUE_APP_API_ADMIN_FAQ_DELETE || '/v1/Admin/InativarFaq',
      },
      documento:{
        getDocumentosUri: process.env.VUE_APP_API_DOCUMENTOS_LIST || '/v1/Admin/Documentos',
        getRegisterDocumentosUri: process.env.VUE_APP_API_DOCUMENTOS_REGISTER || '/v1/Admin/CadastrarDocumento',
        getUpdateDocumentosUri: process.env.VUE_APP_API_DOCUMENTOS_UPDATE || '/v1/Admin/AtualizarDocumento',
        getDeleteDocumentosUri: process.env.VUE_APP_API_DOCUMENTOS_DELETE || '/v1/Admin/ExcluirDocumento',
        getDownloadDocumentoUri: process.env.VUE_APP_API_ADMIN_DOWNLOAD_ARQUIVO || '/v1/Admin/Download',
      },
      repasses:{
        getRepassesUri: process.env.VUE_APP_API_REPASSE_LISTAR || '/v1/Admin/Repasses',
        getRegisterFonteDeDadosRepassesUri: process.env.VUE_APP_API_REPASSE_ENVIAR_FONTE_DE_REPASSES_ARQUIVO || '/v1/Admin/CadastrarRepassesPorArquivo',
        getRegisterRepasseUri: process.env.VUE_APP_API_REPASSE_REGISTER || '/v1/Admin/CadastrarRepasse',
        getUpdateRepasseUri:  process.env.VUE_APP_API_REPASSE_UPDATE || '/v1/Admin/AtualizarRepasse',
        getDeleteRepasseUri: process.env.VUE_APP_API_REPASSE_DELETE || '/v1/Admin/DeletarRepasse',
      },
      paf: {
        getListaDeMateriaisUri: process.env.VUE_APP_API_PAF_OBTER_LISTA_MATERIAIS || '/v1/Admin/ObterListaDeMateriais',
        getSolicitacaoAtualUri: process.env.VUE_APP_API_PAF_OBTER_SOLICITACAO_ATUAL || '/v1/Admin/ObterSolicitacaoAtual',
        getSalvarRecursoItemUri: process.env.VUE_APP_API_PAF_SALVAR_RECURSO_ITEM || '/v1/Admin/SalvarItemRecurso',
        getCadastrarItemUri: process.env.VUE_APP_API_PAF_CADASTRAR_ITEM || '/v1/Admin/CadastrarItem',
        getObterListaDeSolicitacaoUri: process.env.VUE_APP_API_PAF_OBTER_LISTA_SOLICITACAO || '/v1/Admin/ObterListaDeSolicitacao',
        getDeletarRecursoItemUri: process.env.VUE_APP_API_PAF_DELETAR_RECURSO_ITEM || '/v1/Admin/DeletarItemRecurso',
        getEditarRecursoItemUri: process.env.VUE_APP_API_PAF_EDITAR_RECURSO_ITEM || '/v1/Admin/EditarItemRecurso',
        getDeletarSolicitacaoUri: process.env.VUE_APP_API_PAF_DELETAR_SOLICITACAO || '/v1/Admin/DeletarSolicitacao',
        getEnviarSolicitacaoParaGreUri: process.env.VUE_APP_API_PAF_ENVIAR_SOLICITACAO_PARA_GRE || '/v1/Admin/EnviarSolicitacaoParaGre',
        getObterListaDeSolicitacaoGreUri: process.env.VUE_APP_API_PAF_OBTER_LISTA_SOLICITACAO_GRE || '/v1/Admin/ObterListaDeSolicitacaoGre',
        getEnviarParecerGreUri: process.env.VUE_APP_API_PAF_ENVIAR_PARECER_GRE || '/v1/Admin/EnviarParacerGre',
        getEnviarSolicitacaoValidadaUri: process.env.VUE_APP_API_PAF_ENVIAR_SOLICITACAO_VALIDADA_GRE || '/v1/Admin/EnviarSolicitacaoValidadaGre'
      },
      servicos: {
        postHtmlParaPdfUri:
          process.env.VUE_APP_API_SERVICES_POST_CONVERT_HTML_TO_PDF || '/v1/ServicoDePdf/HtmlParaPdf' 
      }
    },
  },
  google: {
    id: process.env.VUE_APP_GOOGLE_ID,
  },
  arquivos:{
    paginaInicial: "/arquivos/PaginaPrincipal/"
  }

}