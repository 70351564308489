import Vue from 'vue'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib' 
import VuePageTransition from 'vue-page-transition'
import '@mdi/font/css/materialdesignicons.css'
import 'animate.css/animate.css'
import VueGtag from "vue-gtag";

import App from './App.vue'
import configurations from "commons/configurations"

import './registerServiceWorker'
import routerFactory from 'router'
import store from 'store'
import vuetify from 'plugins/vuetify'
import axiosPlugin from 'plugins/axios.plugin'
import dayJsPlugin from 'plugins/dayjs.plugin'
import {
  abilitiesPlugin
} from '@casl/vue';
import ability from 'services/abilities/ability';

import {
  PRE_LOGIN_LAYOUT_NAMESPACE,
  UPDATE_BACKGROUND_IMAGE_VISIBILITY
} from "store/modules/pre-login-layout"
import {
  AUTH_NAMESPACE,
  REFRESH_TOKEN,
  LOGOUT
} from "store/modules/auth"

import 'assets/styles/app.scss'

Vue.config.productionTip = false

Vue.use(VuePageTransition)

Vue.use(dayJsPlugin, {
  locale: 'pt-br'
})

Vue.use(axiosPlugin, {
  baseURL: configurations.api.baseUri
})

Vue.use(abilitiesPlugin, ability)

Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, { 
	locale: 'pt-BR',
	decimalLength: 2,
	autoDecimalMode: true,
	min: null,
	max: null,
	defaultValue: 0,
  valueAsInteger: false,
  allowNegative: false,
})

Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
  });
  return formatter.format(value);
});

const router = routerFactory(store)

if(configurations.google.id) {
  Vue.use(VueGtag, {
    config: { id: configurations.google.id },
    appName: configurations.app.name,
    pageTrackerScreenviewEnabled: true,
    pageTrackerUseFullPath: true
  }, router)
}

new Vue({
  data: () => ({
    loading: {
      visible: true,
      content: undefined,
      progress: undefined,
      opacity: undefined
    },
    notification: {
      visible: undefined,
      timeout: undefined,
      color: undefined,
      light: undefined,
      centered: undefined,
      absolute: undefined,
      rounded: undefined,
      top: true,
      right: true,
      bottom: undefined,
      left: undefined,
      content: undefined,
      closable: true
    }
  }),
  router: router,
  store: store,
  vuetify,
  mounted() {
    this.$store.dispatch(`${AUTH_NAMESPACE}/${REFRESH_TOKEN}`)
      .catch(() => {
        this.$store.dispatch(`${AUTH_NAMESPACE}/${LOGOUT}`)
      })
  },
  methods: {
    showPageLoading() {
      this.loading.visible = true;
    },
    hidePageLoading() {
      this.loading.visible = false;
    },
    showDefaultBackgroundImage() {
      this.$store.dispatch(
        `${PRE_LOGIN_LAYOUT_NAMESPACE}/${UPDATE_BACKGROUND_IMAGE_VISIBILITY}`,
        true
      );
    },
    hideDefaultBackgroundImage() {
      this.$store.dispatch(
        `${PRE_LOGIN_LAYOUT_NAMESPACE}/${UPDATE_BACKGROUND_IMAGE_VISIBILITY}`,
        false
      );
    },
  },
  render: h => h(App)
}).$mount('#app')