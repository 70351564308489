import configurations from "commons/configurations";

export const PRE_LOGIN_LAYOUT_NAMESPACE = "preLoginLayout";

// mutations (privadas)
const MUTATE_BACKGROUND_IMAGE = "mutateBackgroundImage";
const MUTATE_BACKGROUND_IMAGE_VISIBILITY = "mutateBackgroundImageVisibility";
const DEFAULT_BACKGROUND_IMAGE =
  configurations.layout.preLogin.backgroundImageUri;
const MUTATE_IS_GENERATING_REPORTS = "mutateIsGeneratingReports";
const MUTATE_REPORT = "mutateReport";
const MUTATE_REPORT_FILTER = "mutateReportFilter";

// actions (publicas)
export const UPDATE_BACKGROUND_IMAGE = "updateBackgroundImage";
export const UPDATE_BACKGROUND_IMAGE_VISIBILITY =
  "updateBackgroundImageVisibility";
export const UPDATE_IS_GENERATING_REPORTS = "updateIsGeneratingReports";
export const UPDATE_REPORT = "updateReport";
export const UPDATE_REPORT_FILTER = "updateReportFilter";
export const CLEAR_REPORT = "clearReport";
export const CLEAR = "clear";

// getters (publicos)
export const BACKGROUND_IMAGE = "bagroundImage";
export const BACKGROUND_IMAGE_VISIBILITY = "backgroundImageVisibility";
export const IS_GENERATING_REPORTS = "generatingReports";
export const REPORT = "report";
export const REPORT_FILTER = "reportFilter";


const getDefaultFilterState = () => {
  return {
    unidadeExecutora: {
      id: 0,
      nome: "",
    },
    municipio: "",
    escolaNome: "",
    greNome: "",
    ano: "",
    filtros: [],
  }
}

export default {
  namespaced: true,
  state: () => ({
    bagroundImage: DEFAULT_BACKGROUND_IMAGE,
    isBackgroundImageVisible: true,
    isGeneratingReports: false,
    report: [],
    reportFilter: {
      unidadeExecutora: {
        id: 0,
        nome: "",
      },
      municipio: "",
      escolaNome: "",
      greNome: "",
      ano: "",
      filtros: [],
    }
  }),
  mutations: {
    [MUTATE_BACKGROUND_IMAGE](state, bagroundImage) {
      state.bagroundImage = bagroundImage;
    },
    [MUTATE_BACKGROUND_IMAGE_VISIBILITY](state, visible) {
      state.isBackgroundImageVisible = visible;
    },
    [MUTATE_IS_GENERATING_REPORTS](state, status) {
      state.isGeneratingReports = status;
    },
    [MUTATE_REPORT_FILTER](state, reportFilter) {
      state.reportFilter = reportFilter;
    },
    [MUTATE_REPORT](state, report) {
      state.report = report;
    },
  },
  actions: {
    async [UPDATE_BACKGROUND_IMAGE]({ commit }, bagroundImage) {
      commit(
        MUTATE_BACKGROUND_IMAGE,
        bagroundImage || DEFAULT_BACKGROUND_IMAGE
      );
    },
    async [UPDATE_BACKGROUND_IMAGE_VISIBILITY]({ commit }, visible) {
      commit(
        MUTATE_BACKGROUND_IMAGE_VISIBILITY,
        typeof visible === "boolean" ? visible : true
      );
    },
    async [UPDATE_IS_GENERATING_REPORTS]({ commit },status) {
      commit(MUTATE_IS_GENERATING_REPORTS, status);
    },
    async [UPDATE_REPORT]({ commit }, report) {
      commit(MUTATE_REPORT, report);
    },
    async [UPDATE_REPORT_FILTER]({commit}, reportFilter) {
      commit(MUTATE_REPORT_FILTER, reportFilter);
    },
    async [CLEAR_REPORT]({commit}) {
      commit(MUTATE_IS_GENERATING_REPORTS, false);
      commit(MUTATE_REPORT, []);
      commit(MUTATE_REPORT_FILTER, getDefaultFilterState());
    },
    async [CLEAR]({ commit }) {
      commit(MUTATE_BACKGROUND_IMAGE, DEFAULT_BACKGROUND_IMAGE);
      commit(MUTATE_BACKGROUND_IMAGE_VISIBILITY, true);
      commit(MUTATE_IS_GENERATING_REPORTS, false);
    },
  },
  getters: {
    [BACKGROUND_IMAGE]: (state) => state.bagroundImage,
    [BACKGROUND_IMAGE_VISIBILITY]: (state) => state.isBackgroundImageVisible,
    [IS_GENERATING_REPORTS]: (state) => state.isGeneratingReports,
    [REPORT]: (state) => state.report,
    [REPORT_FILTER]: (state) => state.reportFilter,
  },
};
