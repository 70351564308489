<template>
  <div class="text-center">
    <v-expand-x-transition>
      <v-dialog v-model="value" persistent overlay-color="white" width="300" :overlay-opacity="opacity">
        <v-card color="primary" dark class="text-center pt-3">
          <v-card-text>
            <span v-html="content"></span>
            <v-progress-linear
              :indeterminate="indeterminate"
              :value="progress"
              color="white"
              class="mb-0 mt-1"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-expand-x-transition>
  </div>
</template>

<script>
export default {
  name: "CarregadorDePagina",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: "Carregando...",
    },
    progress: {
      type: Number,
      default: undefined,
    },
    opacity: {
      type: Number,
      default: undefined,
    }
  },
  computed: {
    indeterminate() {
      return isNaN(parseInt(this.progress));
    },
  },
};
</script>