<template>
  <div id="page-wrapper">
    <img v-if="visibleBackgroundImage" alt="Imagem de fundo" id="background-image" :src="backgroundImageSrc" />
    <v-main id="main">
      <vue-page-transition name="fade-in-right">
        <router-view class="page" />
      </vue-page-transition>
    </v-main>
  </div>
</template>

<script>
import { PRE_LOGIN_LAYOUT_NAMESPACE, BACKGROUND_IMAGE, BACKGROUND_IMAGE_VISIBILITY } from "store/modules/pre-login-layout";

export default {
  name: "PreLoginLayout",
  data: () => ({
  }),
  computed: {
    backgroundImageSrc() {
      return this.$store.getters[`${PRE_LOGIN_LAYOUT_NAMESPACE}/${BACKGROUND_IMAGE}`];
    },
    visibleBackgroundImage() {
      return this.$store.getters[`${PRE_LOGIN_LAYOUT_NAMESPACE}/${BACKGROUND_IMAGE_VISIBILITY}`];
    },
  },
};
</script>

<style lang="scss" scoped>
#page-wrapper {
  background: #f5f5f5;

  #background-image {
    position: fixed;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  #main {
    .page {
      min-height: 75vh;
    }
  }
}
</style>