import Vue from 'vue'
import VueRouter from 'vue-router'

import configurations from 'commons/configurations'
import {
  AUTH_NAMESPACE,
  USER
} from 'store/modules/auth.js'
import PreLoginLayout from 'views/pre-login/base-pre-login.vue'

Vue.use(VueRouter)

const routerFactory = function (store) {
  const routes = [{
      path: '*',
      name: 'not-found',
      component: () => import( /* webpackChunkName: "not-found" */ 'views/404.vue'),
    },
    {
      path: '/',
      component: PreLoginLayout,
      children: [{
          path: '/',
          name: 'ladingpage',
          component: () => import( /* webpackChunkName: "ladingpage" */ 'views/pre-login/lading-page/base-lading-page.vue'),
          meta: {
            title: configurations.app.name,
            showPageLoading: true
          },
        },
        {
          path: '/login',
          name: 'login',
          component: () => import( /* webpackChunkName: "ladingpage" */ 'views/pre-login/login.vue'),
          meta: {
            title: configurations.app.name,
            showPageLoading: true
          },      
        },
        {
          path: '/admin',
            name: 'admin',
              component: () => import( /* webpackChunkName: "ladingpage" */ 'views/post-login/base-post-login.vue'),
                meta: {
                  title: configurations.app.name,
                    showPageLoading: true,
                    requiresAuth: true
                },
                  children: [
                    { path: '/admin/perfil', component: () => import('~/views/post-login/users/manage-users-profiles.vue')},
                    { path: '/admin/usuario', component: () => import('~/views/post-login/users/register-user.vue')},
                    { path: '/admin/faq', component: () => import('~/views/post-login/faq/manage-faq.vue')},
                    { path: '/admin/documentos', component: () => import('~/views/post-login/documentos/manage-documentos.vue')},
                    { path: '/admin/repasses', component: () => import('~/views/post-login/repasses/manage-repasses.vue')},
                    { path: '/admin/paf', component: () => import('~/views/post-login/paf/manage-paf.vue')},
                    { path: '/admin/paf-gre', component: () => import('~/views/post-login/paf/manage-paf-gre.vue')},
                    { path: '/admin/vinculacao', component: () => import('~/views/post-login/users/manage-user-vinculacao')},
                    
                  ],
        },
        {
          path: '/NovaSenha/:username/:token',
          name:'password-recovery',
          meta: {
            title: configurations.app.name,
            showPageLoading: true
          },
          component: () => import('views/pre-login/new-password.vue')
        },
        {
          path: "EsqueciASenha",
          name: "passwordrecovery",
          component: () =>
            import(
              /* webpackChunkName: "password-recovery" */ "views/pre-login/password-recovery.vue"
            ),
          meta: {
            title: "Esqueci a Senha",
            showPageLoading: true,
            showBackground: true,
          },
        },
      ]
    }
    
  ]

  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })

  router.beforeEach((to, from, next) => {
    function updateTitleAndMetadata() {
      // This goes through the matched routes from last to first, finding the closest route with a title.
      // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
      // `/nested`'s will be chosen.
      const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

      // Find the nearest route element with meta tags.
      const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

      const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

      // If a route with a title was found, set the document (page) title to that value.
      if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
      } else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
      }

      // Remove any stale meta tags from the document using the key attribute we set below.
      Array.from(document.querySelectorAll('[data-vue-router-controlled]')).forEach(el => el.parentNode.removeChild(el));

      // Skip rendering meta tags if there are none.
      if (!nearestWithMeta) return next();

      // Turn the meta tag definitions into actual elements in the head.
      nearestWithMeta.meta.metaTags.map(tagDef => {
          const tag = document.createElement('meta');

          Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
          });

          // We use this to track which meta tags we create so we don't interfere with other ones.
          tag.setAttribute('data-vue-router-controlled', '');

          return tag;
        })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));
    }

    function authorize() {
      if (to.matched.some(record => record.meta.requiresAuth)) {
        const user = store.getters[`${AUTH_NAMESPACE}/${USER}`]
        if (user) {
          next()
          return
        }
        next({
          path: '/login'
        })
      } else {
        next()
      }
    }

    updateTitleAndMetadata();

    authorize();
  });

  router.afterEach((to) => {
    if (to.meta.showPageLoading) {
      router.app.showPageLoading();
    }

    if (to.meta.showBackground) {
      router.app.showDefaultBackgroundImage();
    } else {
      router.app.hideDefaultBackgroundImage();
    }
  })

  return router
}


export default routerFactory