import { Ability } from '@casl/ability';
import { ABILITIES } from "store/modules/auth";

export function ConvertPermissionsIntoAbilities(permissions) {
  if(!Array.isArray(permissions)) {
    return [];
  }

  let abilities = [];
  let permissionName = undefined;

  for(let permission of permissions) {
    permissionName = permission.nome.toLocaleLowerCase().trim();
    let arraySubject = permission.nome.split(' ');
    let nameSubject  = '';

    for (let index = 1; index < arraySubject.length; index++) {
      nameSubject += `${arraySubject[index]} `
    }

    if(permissionName.includes('visualizar'))
      abilities.push({ action: 'read', subject: nameSubject.trim() });

    if(permissionName.includes('adicionar'))
      abilities.push({ action: 'create', subject: nameSubject.trim() });

    if(permissionName.includes('editar')) 
      abilities.push({ action: 'edit', subject: nameSubject.trim() });
    
    if(permissionName.includes('deletar')) 
      abilities.push({ action: 'delete', subject: nameSubject.trim() });
  }
  return abilities;
}

export default new Ability(JSON.parse(atob(localStorage.getItem(ABILITIES) || '') || '[]') || []);